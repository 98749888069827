import { ApiCrud } from 'adready-api/api/api-crud';
import http from 'adready-api/api/http';

const appConsoleApi = new ApiCrud(http.AXIOS, {
  getSolutionsByAdvertiser: '/ac/advertisers/:id/solutions',
  activateSolution: 'POST /users/:userId/activateSolution',
  fetchCampaignOptions: '/ac/advertisers/:id/campaigns',
  fetchAdGroupOptions: 'POST /ac/advertisers/:id/ad_groups',
  fetchMediaTypeOptions: '/ac/advertisers/:id/mediatypes',
  fetchAudienceOptions: '/ac/advertisers/:id/audiences',
  fetchCreativeOptions: '/ac/advertisers/:id/creatives',
  fetchKeywordOptions: '/ac/advertisers/:id/keywords',
  fetchConversionPixelOptions: '/ac/advertisers/:id/pixels',
  fetchFlipCampaignOptions: 'advertisers/:id/io_names',
  fetchFlipAdGroupOptions: 'ios/:id/lineItemNamesData',
  fetchFlipMediaTypeOptions: 'POST advertisers/:id/media_types',
  fetchFlipAudienceOptions: 'POST advertisers/:id/audiences',
  fetchFlipCreativeOptions: 'POST advertisers/:id/creatives',
  fetchDemoAdvertiserMappings: '/demoAdvtMapping',

  // api calls related to Programmatic Media solution
  fetchBrandSummary: '/ac/advertisers/:id/solutions/:solutionId/brand/summary',
  fetchBrandTopPerformerData: '/ac/advertisers/:id/solutions/:solutionId/brand/top_performers',
  fetchBrandDistributionData:
    '/ac/advertisers/:id/solutions/:solutionId/brand/measurements/distribution',
  fetchBrandMetricDistributionData:
    '/ac/advertisers/:id/solutions/:solutionId/brand/metrics/distribution',
  fetchBrandDimensionPerformance: '/ac/advertisers/:id/solutions/:solutionId/brand/performance',
  fetchBrandMeasurementDailyViewData:
    '/ac/advertisers/:id/solutions/:solutionId/brand/measurements/daily',
  fetchBrandMetricDailyViewData: '/ac/advertisers/:id/solutions/:solutionId/brand/metrics/daily',
  fetchBrandDimensionReport: 'POST /ac/advertisers/:id/solutions/:solutionId/brand/reports',

  // api calls related to Google Search solution
  fetchSearchSummary: '/ac/advertisers/:id/solutions/:solutionId/brand/summary',
  fetchSearchTopPerformerData: '/ac/advertisers/:id/solutions/:solutionId/brand/top_performers',
  fetchSearchDistributionData:
    '/ac/advertisers/:id/solutions/:solutionId/brand/measurements/distribution',
  fetchSearchMetricDistributionData:
    '/ac/advertisers/:id/solutions/:solutionId/brand/metrics/distribution',
  fetchSearchDimensionPerformance: '/ac/advertisers/:id/solutions/:solutionId/brand/performance',
  fetchSearchMeasurementDailyViewData:
    '/ac/advertisers/:id/solutions/:solutionId/brand/measurements/daily',
  fetchSearchMetricDailyViewData: '/ac/advertisers/:id/solutions/:solutionId/brand/metrics/daily',
  fetchSearchDimensionReport: 'POST /ac/advertisers/:id/solutions/:solutionId/brand/reports',

  // api calls related to Meta solution
  fetchSocialSummary: '/ac/advertisers/:id/solutions/:solutionId/brand/summary',
  fetchSocialTopPerformerData: '/ac/advertisers/:id/solutions/:solutionId/brand/top_performers',
  fetchSocialDistributionData:
    '/ac/advertisers/:id/solutions/:solutionId/brand/measurements/distribution',
  fetchSocialMetricDistributionData:
    '/ac/advertisers/:id/solutions/:solutionId/brand/metrics/distribution',
  fetchSocialDimensionPerformance: '/ac/advertisers/:id/solutions/:solutionId/brand/performance',
  fetchSocialMeasurementDailyViewData:
    '/ac/advertisers/:id/solutions/:solutionId/brand/measurements/daily',
  fetchSocialMetricDailyViewData: '/ac/advertisers/:id/solutions/:solutionId/brand/metrics/daily',
  fetchSocialDimensionReport: 'POST /ac/advertisers/:id/solutions/:solutionId/brand/reports',

  // api calls related to DOOH
  fetchDoohSummary: '/ac/advertisers/:id/solutions/:solutionId/brand/summary',
  fetchDoohTopPerformerData: '/ac/advertisers/:id/solutions/:solutionId/brand/top_performers',
  fetchDoohDistributionData:
    '/ac/advertisers/:id/solutions/:solutionId/brand/measurements/distribution',
  fetchDoohMetricDistributionData:
    '/ac/advertisers/:id/solutions/:solutionId/brand/metrics/distribution',
  fetchDoohDimensionPerformance: '/ac/advertisers/:id/solutions/:solutionId/brand/performance',
  fetchDoohMeasurementDailyViewData:
    '/ac/advertisers/:id/solutions/:solutionId/brand/measurements/daily',
  fetchDoohMetricDailyViewData: '/ac/advertisers/:id/solutions/:solutionId/brand/metrics/daily',
  fetchDoohDimensionReport: 'POST /ac/advertisers/:id/solutions/:solutionId/brand/reports',

  // api calls related to Tools
  fetchMyReports: '/tableau/views/:advertiser',
  // api calls related to Tools
  fetchMyAccountReports: '/tableau/views/account/:accountId',
  downloadMyReport: '/tableau/:advertiserId/download/views/:viewId/:type',

  // api calls related to Converged TV
  fetchCTVSummary: '/ac/advertisers/:advertiserId/solutions/:solutionId/convergedtv/summary',

  flipPixels: 'advertisers/:advertiserId/flipPixels',
  flipPixelFiresSummary: 'advertisers/:advertiserId/flipPixels/:pixelId/fires/summary',
  flipPixelCategories: 'advertisers/:advertiserId/flipPixels/:pixelId/categories',
  flipPixelFires: 'advertisers/:advertiserId/flipPixels/:pixelId/fires',
  flipDailyPixelFires: 'advertisers/:advertiserId/flipPixels/:pixelId/fires/daily',

  getUserSavedReports: '/ac/advertisers/:advertiserId/reportTemplate/getUserSavedReports',

  // api calls for using Tableau REST Api
  getTableauRestToken: '/token/tableau/sign/:advertiserId',
});

export default appConsoleApi;
